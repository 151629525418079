body {
  margin: 0;
  overflow: hidden;
}

.parquet-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.parquet-option {
  cursor: pointer;
  border: 2px solid transparent;
  max-width:100px!important;
  width:100px;
}

.parquet-option.selected {
  border: 2px solid blue;
}

.view-in-room-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
}
